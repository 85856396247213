import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import NotificationDataTable from "../../components/NotificationDataTable";
import toaster from "../../modules/toaster";
import {
  deleteNotification,
  getNotifications,
} from "../../reducers/Notifications";
import "./Notification.css";

const selecttableHead = [
  "notificationEn",
  "notificationAr",
  "createdAt",
  "Actions",
];

const rowsPerPage = 10;

const Notification = ({
  notifications,
  getNotifications,
  deleteNotification,
  isPending,
  history,
}) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    notificationEn: true,
    notificationAr: true,
    createdAt: false,
    Actions: true,
  });
  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    setData(notifications);
  }, [notifications]);

  const handleAddCase = () => {
    history.push("addNotification");
  };
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDelete=()=>{
    
    
    if (selected.length === 0) {
      toaster.warning("please select some notifications");
    } else {
      Promise.allSettled(
        selected.map(e=> {
          return deleteNotification(e)
        })
      ).then(responseArr => {
        responseArr.forEach(res=>{
          console.log(res);
      
        })
      })
      setSelected([])
    }
  }
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="header">
        <h4 className="title">Notification</h4>
      </div>
      <div className="card">
        {data.length > 0 ? (
          <NewDataTable
            title={"Add a notification"}
            csvTitle={"noification"}
            add={handleAddCase}
            data={data}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
             
              <Pagination size="small" 
              total={data.length} 
              onChange={handlePaginationChange} />
            }
          >
            {data
              ?.slice((page-1) * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <NotificationDataTable
                    key={row.objectId}
                    notification={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    isPending={isPending}
                    deleteNotification={deleteNotification}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
          <p style={{ textAlign: "center" }}>No  Notification Found</p>
          <button
            className="addButton"
            onClick={() => {
              handleAddCase
            }}
          >
            <BsPlus size={24} />
            Add A new Notificationd
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    notifications: state.Notifications.notifications,
    isPending: state.Notifications.isPending,
  }),
  (dispatch) => ({
    getNotifications: () => dispatch(getNotifications()),
    deleteNotification: (id) => dispatch(deleteNotification(id)),
  })
)(Notification);
