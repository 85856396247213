/* eslint-disable no-undef */
export const config = {
  isProd: process.env.NODE_ENV === "production",
  stgApiUrl: "https://stgapi.ikhair.net",
  //stgApiUrl: "http://localhost:9000",
  //stgApiUrl: 'https://api2.ikhair.net',
  ikhair: "https://api2.ikhair.net",
  ikhairstg: "https://stgapi.ikhair.net",
  kheerom: "https://api-om.ikhair.net",
  jonation: "https://jonationapi.ikhair.net",
  prodApibagisUrl: "https://bagisapi.instabackend.io",
  kongPassword: "n7s7id63",
  appgainServer: "https://feapi.appgain.io",
  appApiKey: "b1e485058f890d183cf4fd9e9b63f28b7b2548316158b82e407f07b8cc217c8e",
  appId: "5d1085ebb457a6000c34e0b0",
  appBackend:
    "https://appbackend.appgain.io/5864ef5223fcb95c7a99a156/ikhair",
};
export const getServerUrl = () => config.ikhair;
