import { Grid, IconButton } from "@material-ui/core";
import minus from "assets/images/minuscircle.svg";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Joi from "joi";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BiPlus } from "react-icons/bi";
import { connect } from "react-redux";
import danger from "../../../assets/images/danger.svg";
import uploadOnS3 from "../../../helpers/uploadOnS3";
import toaster from "../../../modules/toaster";
import {
  createCase,
  editSMSPayment,
  updateUrgentCase,
} from "../../../reducers/Cases";

const schema = Joi.object({
  nameEN: Joi.string().max(60).required(),
  nameAR: Joi.string().max(60).required(),
  infoAR: Joi.string().max(1000).required(),
  infoEN: Joi.string().max(1000).required(),
  target_amount: Joi.number().min(0).required(),
  mediaURL: Joi.string().max(150).required(),
  order: Joi.number().min(0).required(),
  sms_payment: Joi.any(),
  act_id: Joi.any(),
  org_id: Joi.any(),
});

class Case extends Component {
  state = {
    smsRows: [0],
    isEdit: false,
    showSMS: false,
    isInfoRequired: false,
    values: {
      actid: "",
      nameAR: "",
      nameEN: "",
      order: 0,
      infoAR: EditorState.createEmpty(),
      infoEN: EditorState.createEmpty(),
      targetAmount: 0,
      collectedAmount: 0,
      imageUpload: "",
      sms_payment: [
        {
          mno: 0,
          price: 0,
          short_code: 0,
          keyword_en: "",
          keyword_ar: "",
          id: 0,
        },
      ],
    },
  };

  componentDidMount() {
    let params = this.props.location.search;
    if (params.includes("edit")) {
      this.setState({ isEdit: true });
      this.setState({ showSMS: true });
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            nameAR: this.props.currentCase.nameAR,
            actid: this.props.currentCase.actid,
            nameEN: this.props.currentCase.nameEN,
            order: this.props.currentCase.order,
            infoAR: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentCase.infoAR
                    ? this.props.currentCase.infoAR
                    : `<p>No description</p>`
                )
              )
            ),

            infoEN: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentCase.infoEN
                    ? this.props.currentCase.infoEN
                    : `<p>No description</p>`
                )
              )
            ),
            targetAmount: this.props.currentCase.targetAmount,
            collectedAmount: this.props.currentCase.collectedAmount,
            imageUpload: this.props.currentCase.mediaURL,

            sms_payment: this.props.currentCase.sms_payment.map(
              (sms, index) => {
                return {
                  mno: sms.mno,
                  price: sms.price,
                  short_code: sms.short_code,
                  keyword_en: sms.keyword_en,
                  keyword_ar: sms.keyword_ar,
                  id: sms.id,
                };
              }
            ),
          },
        };
      });

      // this.handleDuplicateOrEditSMSFields();
    } else if (params.includes("duplicate")) {
      this.setState({ showSMS: true });
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            nameAR: this.props.currentCase.nameAR,
            actid: this.props.currentCase.actid,
            nameEN: this.props.currentCase.nameEN,
            order: this.props.currentCase.order,
            infoAR: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentCase.infoAR
                    ? this.props.currentCase.infoAR
                    : `<p>No description</p>`
                )
              )
            ),

            infoEN: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentCase.infoEN
                    ? this.props.currentCase.infoEN
                    : `<p>No description</p>`
                )
              )
            ),
            targetAmount: this.props.currentCase.targetAmount,
            collectedAmount: this.props.currentCase.collectedAmount,
            imageUpload: this.props.currentCase.mediaURL,
            sms_payment: this.props.currentCase.sms_payment.map(
              (sms, index) => {
                return {
                  mno: sms.mno,
                  price: sms.price,
                  short_code: sms.short_code,
                  keyword_en: sms.keyword_en,
                  keyword_ar: sms.keyword_ar,
                  id: sms.id,
                };
              }
            ),
          },
        };
      });
      // this.handleDuplicateOrEditSMSFields();
    }
  }

  handleDuplicateOrEditSMSFields = () => {
    if (this.props.currentCase && this.props.currentCase.sms_payment)
      for (let i = 1; i < this.props.currentCase.sms_payment.length; i++)
        this.addSmsRow();
  };

  onEditorStateChange = (infoAR) => {
    this.setState({
      infoAR,
    });
  };
  // change all
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          [name]: value,
        },
      };
    });
  };
  // change sms
  mnoChange = (keyId, newValue) => {
    const { value } = newValue?.target;
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            mno: value,
          };
        }
        return key;
      });
      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  shortCodeChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            short_code: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  keywordChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            keyword_ar: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  keywordENChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            keyword_en: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  priceChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            price: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  addSmsRowCheck = () => {
    let params = this.props.location.search;
    if (
      !params.includes("duplicate") &&
      !this.props.currentCase.sms_payment.length > 0
    ) {
      let rows = this.state.smsRows;
      rows.push(rows[this.state.smsRows.length - 1] + 1);
      this.setState({
        smsRows: rows,
      });
      this.setState((prevState) => {
        const newValues = [
          ...(Array.isArray(prevState.values.sms_payment) &&
            prevState.values.sms_payment),
          {
            mno: 0,
            price: 0,
            short_code: 0,
            keyword_en: "",
            keyword_ar: "",
            id: Date.now() % 100000,
          },
        ];
        return {
          values: {
            ...prevState.values,
            sms_payment: newValues,
          },
        };
      });
    }
  };
  addSmsRow = () => {
    //2
    let rows = this.state.smsRows;
    rows.push(rows[this.state.smsRows.length - 1] + 1);
    this.setState({
      smsRows: rows,
    });
    this.setState((prevState) => {
      const newValues = [
        ...(Array.isArray(prevState.values.sms_payment) &&
          prevState.values.sms_payment),
        {
          mno: 0,
          price: 0,
          short_code: 0,
          keyword_en: "",
          keyword_ar: "",
          id: Date.now() % 100000,
        },
      ];
      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  removeSmsRow = (rowNum) => {
    let rows = this.state.smsRows.filter((num) => num != rowNum);
    this.setState({
      smsRows: rows,
    });
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.filter(
        (key) => key.id !== rowNum
      );

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };

  // submit
  storeImageHandler = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          imageUpload: file,
        },
      };
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { values } = this.state;
    const {
      location,
      selectedOrg,
      duplicateInit,
      currentCase,
      createCase,
      history,
      updateUrgentCase,
      editSMSPayment,
    } = this.props;
    const params = location.search;
    const isEditMode = params.includes("edit");

    // Image upload handling
    let imgURL =
      values.imageUpload && values.imageUpload.name
        ? (
            await uploadOnS3(
              values.imageUpload,
              values.imageUpload.name.split(".").slice(0, -1).join("."),
              "projects"
            )
          ).Location
        : values.imageUpload;

    // Validation for organization selection
    if (!isEditMode && (!selectedOrg || selectedOrg === "")) {
      return toaster.error(
        "You must select an organization before creating a Case."
      );
    }

    // Values transformation
    values = {
      ...values,
      act_id: Number(values.actid),
      org_id: isEditMode ? duplicateInit : Number(selectedOrg),
      target_amount: Number(values.targetAmount),
      order: Number(values.order),
      sms_payment:
        values.sms_payment && values.sms_payment.length > 0
          ? values.sms_payment.filter(Boolean).map((sms) => ({
              ...sms,
              mno: Number(sms.mno),
              price: Number(sms.price),
              short_code: Number(sms.short_code),
            }))
          : [],
    };

    delete values.actid;
    delete values.targetAmount;

    // Case creation
    if (!isEditMode) {
      if (!this.state.showSMS) {
        delete values.sms_payment;
      }

      if (imgURL) {
        values = { ...values, mediaURL: imgURL };
        delete values.imageUpload;
      }

      values = {
        ...values,
        infoAR: stateToHTML(values.infoAR.getCurrentContent()),
        infoEN: stateToHTML(values.infoEN.getCurrentContent()),
      };

      // const { error } = schema.validate(values);
      // if (error) {
      //   return toaster.error(error.details[0].message);
      // }

      return createCase(values)
        .then(() => history.push("/urgent/list"))
        .catch((err) => console.error("error Case project: ", err));
    }

    // Case update (edit)
    let updatedInfo = null;
    if (!this.compareCaseInfo(currentCase, values)) {
      values = {
        ...values,
        infoAR: stateToHTML(values.infoAR.getCurrentContent()),
        infoEN: stateToHTML(values.infoEN.getCurrentContent()),
      };
    }

    updatedInfo = {
      ...values,
      nameEN: values.nameEN.toString(),
      nameAR: values.nameAR.toString(),
      collected_amount: Number(values.collectedAmount),
      mediaURL: imgURL || values.mediaURL,
    };

    const removedSMSs = this.getRemovedSMSs(currentCase, values);
    const addedSMSs = this.getAddedSMSs(currentCase, values);
    const updatedSMSs = this.getUpdatedSMSs(currentCase, values);

    let smsPayload = null;
    if (
      removedSMSs.length > 0 ||
      addedSMSs.length > 0 ||
      updatedSMSs.length > 0
    ) {
      smsPayload = {
        id: currentCase.id,
        sms_payment_to_remove: removedSMSs.length > 0 ? removedSMSs : undefined,
        sms_payment_to_add:
          addedSMSs.length > 0
            ? addedSMSs.map((sms) => ({ ...sms, id: undefined }))
            : undefined,
        sms_payment_to_update: updatedSMSs.length > 0 ? updatedSMSs : undefined,
      };
    }

    if (smsPayload) await editSMSPayment(smsPayload);

    if (updatedInfo) {
      // const { error } = schema.validate(updatedInfo);
      // if (error) {
      //   return toaster.error(error.details[0].message);
      // }

      await updateUrgentCase({
        cause_data_to_update: updatedInfo,
        id: currentCase.id,
      });

      history.push("/urgent/list");
    }
  };

  // handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let values = this.state.values;

  //   let params = this.props.location.search;

  //   let imgURL = "";
  //   if (values.imageUpload.name) {
  //     const data = await uploadOnS3(
  //       values.imageUpload,
  //       values.imageUpload.name.split(".").slice(0, -1).join("."),
  //       "projects"
  //     );

  //     imgURL = data.Location;
  //   }
  //   if (!values.imageUpload.name && values.imageUpload) {
  //     imgURL = values.imageUpload;
  //   }

  //   if (
  //     !params.includes("edit") &&
  //     (!this.props.selectedOrg || this.props.selectedOrg == "")
  //   ) {
  //     return toaster.error(
  //       "You must select an organization before creating a Case."
  //     );
  //   }

  //   values.act_id = Number(values.actid);
  //   if (!params.includes("edit"))
  //     values.org_id = Number(this.props.selectedOrg);
  //   else values.org_id = this.props.duplicateInit;

  //   values.target_amount = Number(values.targetAmount);
  //   delete values.actid;
  //   delete values.targetAmount;

  //   if (values.sms_payment && values.sms_payment.length > 0) {
  //     values.sms_payment = values.sms_payment.filter((sms) => !!sms);
  //     for (let sms of values.sms_payment) {
  //       sms.mno = Number(sms.mno);
  //       sms.price = Number(sms.price);
  //       sms.short_code = Number(sms.short_code);
  //     }
  //   }
  //   values.order = Number(values.order);

  //   if (!params.includes("edit")) {
  //     delete values.isactive;
  //     delete values.id;

  //     delete values.collectedAmount;
  //     for (let sms of values.sms_payment) {
  //       delete sms.id;
  //     }
  //     if (!this.state.showSMS) {
  //       delete values.sms_payment;
  //     }

  //     if (imgURL) {
  //       delete values.imageUpload;
  //       values = { ...values, mediaURL: imgURL };
  //     }
  //     values = {
  //       ...values,
  //       infoAR: stateToHTML(values.infoAR.getCurrentContent()),
  //       infoEN: stateToHTML(values.infoEN.getCurrentContent()),
  //     };

  //     const { error } = schema.validate(values);

  //     if (error) {
  //       return toaster.error(error.details[0].message);
  //     }

  //     return this.props
  //       .createCase(values)
  //       .then(() => {
  //         this.props.history.push("/urgent/list");
  //       })
  //       .catch((err) => {
  //         console.error("error Case project: ", err);
  //       });
  //   }

  //   //update case (edit)

  //   let removedSMSs = [];

  //   let addedSMSs = [];

  //   let updatedSMSs = [];

  //   let updatedInfo = null;

  //   const oldCase = this.props.currentCase;

  //   if (!this.compareCaseInfo(oldCase, values))
  //     values = {
  //       ...values,
  //       infoAR: stateToHTML(values.infoAR.getCurrentContent()),
  //       infoEN: stateToHTML(values.infoEN.getCurrentContent()),
  //     };
  //   updatedInfo = {
  //     infoAR: values.infoAR,
  //     infoEN: values.infoEN,
  //     nameEN: values.nameEN.toString(),
  //     nameAR: values.nameAR.toString(),
  //     order: values.order,
  //     target_amount: values.target_amount,
  //     collected_amount: Number(values.collectedAmount),
  //     act_id: values.act_id,
  //     mediaURL: values.mediaURL,
  //   };

  //   if (imgURL) {
  //     updatedInfo = { ...updatedInfo, mediaURL: imgURL };
  //   }

  //   removedSMSs = this.getRemovedSMSs(oldCase, values);
  //   addedSMSs = this.getAddedSMSs(oldCase, values);
  //   updatedSMSs = this.getUpdatedSMSs(oldCase, values);

  //   let smsPayload = null;

  //   if (
  //     removedSMSs.length > 0 ||
  //     addedSMSs.length > 0 ||
  //     updatedSMSs.length > 0
  //   ) {
  //     smsPayload = {};

  //     if (removedSMSs.length > 0) {
  //       smsPayload.sms_payment_to_remove = removedSMSs;
  //     }

  //     if (addedSMSs.length > 0) {
  //       smsPayload.sms_payment_to_add = addedSMSs;
  //     }

  //     if (updatedSMSs.length > 0) {
  //       smsPayload.sms_payment_to_update = updatedSMSs;
  //     }

  //     smsPayload.id = oldCase.id;
  //   }
  //   if (smsPayload?.sms_payment_to_add)
  //     for (let sms of smsPayload.sms_payment_to_add) {
  //       delete sms.id;
  //     }
  //   if (smsPayload) await this.props.editSMSPayment(smsPayload);
  //   if (updatedInfo) {
  //     const { error } = schema.validate(updatedInfo);

  //     if (error) {
  //       return toaster.error(error.details[0].message);
  //     }

  //     await this.props.updateUrgentCase({
  //       cause_data_to_update: updatedInfo,
  //       id: oldCase.id,
  //     });
  //     this.props.history.push("/urgent/list");
  //   }
  // };

  getRemovedSMSs = (oldCase, newCase) => {
    let smsList = [];
    for (let oldSms of oldCase.sms_payment) {
      let found = false;
      for (let sms of newCase.sms_payment)
        if (oldSms.id == sms.id) found = true;
      if (!found) smsList.push(oldSms.id);
    }

    return smsList;
  };

  getAddedSMSs = (oldCase, newCase) => {
    let smsList = [];
    for (let sms of newCase.sms_payment) {
      let found = false;
      for (let oldSms of oldCase.sms_payment)
        if (oldSms.id == sms.id) found = true;
      if (!found) smsList.push(sms);
    }
    return smsList;
  };

  getUpdatedSMSs = (oldCase, newCase) => {
    let smsList = [];
    for (let sms of newCase.sms_payment) {
      let updated = false;
      for (let oldSms of oldCase.sms_payment)
        if (oldSms.id == sms.id && !this.compareSMSs(oldSms, sms))
          updated = true;
      if (updated) smsList.push(sms);
    }
    return smsList;
  };

  compareSMSs = (oldSms, newSms) => {
    return (
      newSms.keyword_ar === oldSms.keyword_ar &&
      newSms.mno === oldSms.mno &&
      newSms.keyword_en === oldSms.keyword_en &&
      newSms.price === oldSms.price &&
      newSms.short_code === oldSms.short_code
    );
  };

  compareCaseInfo = (oldCase, newCase) => {
    return (
      oldCase.infoAR == newCase.infoAR &&
      oldCase.infoEN == newCase.infoEN &&
      oldCase.nameEN == newCase.nameEN &&
      oldCase.nameAR == newCase.nameAR &&
      oldCase.actid == newCase.act_id &&
      oldCase.targetAmount == newCase.target_amount &&
      oldCase.collectedAmount == newCase.collectedAmount &&
      oldCase.mediaURL == newCase.mediaURL &&
      oldCase.imageUpload == newCase.imageUpload
    );
  };

  render() {
    const { image, currentCase } = this.props;

    const {
      actid,
      nameAR,
      nameEN,
      order,
      targetAmount,
      collectedAmount,
      imageUpload,
    } = this.state.values;

    return (
      <div dir="rtl">
        <div className="header">
          {!this.state.isEdit && <h4>New Case</h4>}
          {this.state.isEdit && <h4>Edit Case</h4>}
        </div>
        <form
          className="card"
          style={{ padding: "12px 24px" }}
          onSubmit={this.handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item md={6} className="form-group">
                <label className=" control-label">الفئة</label>

                <select
                  className="newProjectSelect"
                  name="actid"
                  required
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={actid}
                >
                  <option value=""></option>
                  {this.props.activities?.map((activity) => (
                    <option key={activity.id} value={activity.id}>
                      {/* {this.state.isEdit&& actid==activity.id && (activity.nameAR) } */}
                      {activity.nameAR}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>

            <Grid item md={6} className="form-group">
              <label className=" control-label">اسم الحالة</label>

              <input
                type="text"
                name="nameAR"
                value={nameAR}
                required
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} className="form-group">
              <label className="col-sm-12 control-label">Case Name</label>

              <input
                type="text"
                name="nameEN"
                value={nameEN || currentCase?.nameEN}
                required
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Grid>

            <Grid item sm={12} className="form-group">
              <label className="col-sm-12 control-label">Case Order</label>

              <input
                type="number"
                name="order"
                value={order || currentCase?.order}
                required
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Grid>
            <Grid item sm={12} className="form-group">
              <label className="col-sm-12 control-label">وصف الحالة</label>

              <Editor
                className="newProjectTextArea"
                editorState={this.state.values.infoAR}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  this.setState((prevState) => {
                    return {
                      values: {
                        ...prevState.values,
                        infoAR: e,
                      },
                    };
                  });
                }}
                onFocus={() => this.setState({ isInfoRequired: false })}
                onBlur={() =>
                  this.state.values.infoAR
                    .getCurrentContent()
                    .getPlainText()
                    .trim().length <= 0 &&
                  this.setState({ isInfoRequired: true })
                }
              />
            </Grid>

            <Grid item sm={12} className="form-group">
              <label className="col-sm-12 control-label">
                Case Description
              </label>

              <Editor
                className="newProjectTextArea"
                editorState={this.state.values.infoEN}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  this.setState((prevState) => {
                    return {
                      values: {
                        ...prevState.values,
                        infoEN: e,
                      },
                    };
                  });
                }}
                onFocus={() => this.setState({ isInfoRequired: false })}
                onBlur={() =>
                  this.state.values.infoEN
                    .getCurrentContent()
                    .getPlainText()
                    .trim().length <= 0 &&
                  this.setState({ isInfoRequired: true })
                }
              />
            </Grid>

            {this.state.isEdit && (
              <Grid item sm={12}>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={12} className="form-group ">
                    <label className="control-label">المبلغ المطلوب جمعه</label>

                    <input
                      type="number"
                      name="targetAmount"
                      required
                      value={targetAmount}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item lg={5} sm={12} className="form-group">
                    <label className=" control-label">
                      مجموع التبرعات الحالى
                    </label>

                    <input
                      type="number"
                      name="collectedAmount"
                      value={collectedAmount}
                      required
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item lg={6} sm={12} className="form-group media">
              <label className="control-label">MediaURL</label>
              <div className=" mediaLabel">
                <input
                  className="newProjectTextArea"
                  // type="text"
                  name="imageUpload"
                  readOnly
                  value={imageUpload?.name || imageUpload}
                />

                <IconButton
                  aria-label="upload picture"
                  component="Label"
                  className="upload"
                >
                  <input
                    name="imageUpload"
                    accept="image/*"
                    type="file"
                    className="input-upload"
                    onChange={this.storeImageHandler}
                  />
                  upload
                </IconButton>
              </div>
            </Grid>
            {!this.state.isEdit && (
              <Grid item lg={3} sm={12} className="form-group media ">
                <label className="control-label">المبلغ المطلوب جمعه</label>

                <input
                  type="number"
                  name="targetAmount"
                  value={targetAmount}
                  required
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
              </Grid>
            )}
            {image && (
              <Grid item sm={12} className="form-group">
                <label className="col-sm-3 control-label">File Name</label>
                <div className="col-sm-7">
                  <div className="file-name">{image.name}</div>
                </div>
              </Grid>
            )}
            <Grid item sm={12}>
              <div className="form-group create-project-custom-table">
                {/* {!this.state.isEdit && ( */}
                <div className="row short-msgs-row add-bank-label">
                  <label className="control-label"> اضافة رسالة نصية</label>

                  <input
                    name="addSMSField"
                    type="checkbox"
                    // disabled={this.state.isEdit}
                    checked={this.state.showSMS}
                    value={this.state.showSMS}
                    onChange={() => {
                      this.setState({ showSMS: !this.state.showSMS });
                      this.addSmsRowCheck();
                    }}
                    data-toggle="checkbox"
                    style={{
                      width: "17px",
                      marginLeft: "11px",
                      height: "17px",
                    }}
                  />
                </div>
                {/* )} */}

                {this.state.showSMS && (
                  <>
                    <div className="row short-msgs-row msgBG">
                      <label className="control-label">
                        <img src={danger} alt="danger" />
                        التبرع بالرسائل القصيرة
                      </label>
                      <p className="sms-note">
                        Note that to remove an sms field you have to remove from
                        the end of the list till you reach the target field
                      </p>
                    </div>
                    {this.state.values.sms_payment?.map((rowNum, index) => {
                      const {
                        mno,
                        price,
                        short_code,
                        keyword_en,
                        keyword_ar,
                        id,
                      } = this.state.values.sms_payment[index];
                      return (
                        <Grid
                          container
                          spacing={3}
                          sm={12}
                          key={id}
                          style={{ position: "relative" }}
                        >
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">
                              نوع شبكة المحمول
                            </label>

                            <select
                              // value={sms_payment.mno}
                              className="newProjectSelect"
                              name={`sms_payment[${rowNum}].` + "mno"}
                              required
                              value={mno}
                              onChange={(newValue) => {
                                this.mnoChange(id, newValue);
                              }}
                            >
                              <option></option>
                              {this.props.mnos?.map((mno) => (
                                <option key={mno.id} value={mno.id}>
                                  {mno.nameEN}
                                </option>
                              ))}
                            </select>
                          </Grid>
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">رقم التبرع</label>

                            <input
                              type="number"
                              name={`sms_payment[${rowNum}].` + "short_code"}
                              required
                              value={short_code}
                              placeholder="رقم التبرع"
                              onChange={(e) => {
                                this.shortCodeChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">نص الرسالة</label>

                            <input
                              type="text"
                              name={`sms_payment[${rowNum}].` + "keyword_ar"}
                              required
                              placeholder="نص الرسالة"
                              value={keyword_ar}
                              onChange={(e) => {
                                this.keywordChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={2} sm={3} className="form-group">
                            <label className="control-label">Keyword </label>

                            <input
                              type="text"
                              name={`sms_payment[${rowNum}].` + "keyword_en"}
                              required
                              value={keyword_en}
                              placeholder="Keyword"
                              onChange={(e) => {
                                this.keywordENChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={1} sm={3} className="form-group">
                            <label className="control-label">السعر</label>

                            <input
                              type="number"
                              name={`sms_payment[${rowNum}].` + "price"}
                              required
                              value={price}
                              placeholder="0"
                              onChange={(e) => {
                                this.priceChange(id, e.target.value);
                              }}
                            />

                            {index != 0 && (
                              <img
                                style={{
                                  position: "absolute",
                                  left: "-20px",
                                  top: "45%",
                                }}
                                src={minus}
                                alt="minus"
                                onClick={() => {
                                  this.removeSmsRow(id);
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <button
                      type="button"
                      onClick={this.addSmsRow}
                      className="add-short-msgs-btn"
                    >
                      <BiPlus />
                      اضافة رسالة نصية
                    </button>
                  </>
                )}
              </div>
            </Grid>
          </Grid>

          <button type="submit" className="create-edit-form-btn">
            Confirm
          </button>
        </form>
      </div>
    );
  }
}

// export default connect((state) => ({
//   initialValues: state.Cases.duplicate,
//   currentCase: state.Cases.duplicate,
//   activities: state.Activities.activities,
//   mnos: state.MNOs.mnos,
//   selectedOrg: state.ORGs.selectedOrg,

//   // image: formValueSelector("Case")(state, "imageUpload"),
// }))(
//   reduxForm({
//     form: "Case", // a unique identifier for this form
//     enableReinitialize: true,
//   })(Case)
// );

export default connect(
  (state) => ({
    initialValues: state.Cases.duplicate,
    currentCase: state.Cases.duplicate,
    activities: state.Activities.activities,
    mnos: state.MNOs.mnos,
    selectedOrg: state.ORGs.selectedOrg,
    store: state,
  }),
  (dispatch) => ({
    createCase: (urgentCase) => dispatch(createCase(urgentCase)),
    editSMSPayment: (data) => dispatch(editSMSPayment(data)),
    updateUrgentCase: (data) => dispatch(updateUrgentCase(data)),
  })
)(Case);
Case.propTypes = {
  // onSubmit: PropTypes.func,
  // handleSubmit: PropTypes.func,
  location: PropTypes.object,
  currentCase: PropTypes.object,
  activities: PropTypes.array,
  mnos: PropTypes.array,
  image: PropTypes.object,
  createCase: PropTypes.func,
  editSMSPayment: PropTypes.func,
  updateUrgentCase: PropTypes.func,
  duplicateInit: PropTypes.object,
  selectedOrg: PropTypes.string,
  formValues: PropTypes.object,
  match: PropTypes.object,
};
